<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                RENTAL INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                CHOOSE PLAN
              </v-stepper-step> -->

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-home-circle"
              >
                PROPERTY INFO
              </v-stepper-step>

              <v-divider></v-divider>
              <v-stepper-step
                step="3"
                complete
                complete-icon="mdi-home-circle"
              >
                COVERAGE
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="5">
                YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="12"
        md="9"
        lg="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="font-weight-bold"
              :class="mobileDevice ? 'px-2' : 'px-8'"
              @submit.prevent="submit"
            >
              <div v-if="!userpresent && !loading">
                <v-row class="justify-center">
                  <v-col
                    cols="12"
                    md="10"
                  >
                    <v-card elevation="6">
                      <v-card-text>
                        <div class="text-h3 text-center primary--text my-6">
                          In order to continue with the application, please log in or create a Blanket account.
                        </div>
                        <div>{{ error }}</div>

                        <v-form ref="form">
                          <validation-observer
                            ref="observer"
                            v-slot="{ invalid }"
                          >
                            <v-row class="justify-center mb-4">
                              <v-col
                                cols="10"
                                md="8"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Email"
                                  rules="required|email"
                                >
                                  <v-text-field
                                    v-model="user.email"
                                    label="Email"
                                    outlined
                                    append-icon="mdi-email-outline"
                                    :error-messages="errors"
                                    @click="clickedFieldGlobal('Option userEmail', 19, 'Afficiency Survey v1')"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>

                            <v-row class="justify-center mb-4">
                              <v-col
                                cols="10"
                                md="8"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  vid="password"
                                  name="Password"
                                  rules="required"
                                >
                                  <v-text-field
                                    ref="password"
                                    v-model="user.password"
                                    name="password"
                                    label="Password"
                                    outlined
                                    :type="show ? 'text' : 'password'"
                                    :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off'"
                                    :error-messages="errors"
                                    @click:append="show = !show"
                                  ></v-text-field>
                                </validation-provider>
                              </v-col>
                            </v-row>
                            <v-row class="justify-center">
                              <v-col cols="6">
                                <v-btn
                                  class="primary white--text"
                                  block
                                  large
                                  :disabled="invalid"
                                  @keyup.enter="submit"
                                  @click="loginSubmit"
                                >
                                  <span class="login-btn">Log In</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row class="justify-center mt-4">
                              <v-col class="text-center">
                                <span class="message-text">Don't have an account?</span>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="text-center">
                                <!-- <v-btn
                              x-large
                              text
                              class="primary--text font-weight-bold"
                              @click="createApplicantAccount"
                            >
                              Create One For Me <br> using: {{ user.email }}
                            </v-btn> -->
                                <create-account-button
                                  :button-text="'Create One For Me using: ' + user.email"
                                  :button-classes="['primary--text','font-weight-bold','theme--light','v-btn--text','v-btn']"
                                  :large="true"
                                  :block="false"
                                  :email="user.email"
                                  :text="true"
                                />
                              </v-col>
                            </v-row>
                          </validation-observer>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Coverage
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center mt-0">
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  lg="4"
                >
                  <p
                    class="plan-title text-center font-weight-bold secondary--text"
                    :class="{ flash: isFlashing }"
                  >
                    ${{ quotePremium }}/yr
                  </p>
                  <!-- <div class="text-center">
                    <a
                      class="primary--text text-decoration-underline"
                      @click="
                        rateChangeDialog = true;
                        clickedFieldGlobal(
                          'coverage rateChangeDialog',
                          83,
                          'Renters Survey v1'
                        );
                      "
                    >Why did my rate change?</a>
                  </div> -->
                  <v-divider class="secondary"></v-divider>
                  <p
                    class="text-center text-h5 secondary--text font-weight-bold mb-0 pb-0"
                  >
                    Policy Effective: {{ policyDates.effectiveDate }} <br>
                    Policy Expiration: {{ policyDates.expirationDate }}
                  </p>
                </v-col>
              </v-row>
              <!-- <v-dialog
                v-model="rateChangeDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Your preliminary quote may have changed once we factored
                      in discounts and additional information that we collected.
                      If you have questions about this quote, please contact us
                      directly at (855) 209-7824 to speak to one of our licensed
                      agents.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        rateChangeDialog = false;
                        clickedFieldGlobal(
                          'coverage rateChangeDialogCloseBtn',
                          84,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center pb-0"
                >
                  <v-btn
                    v-if="!showOptions"
                    x-large
                    color="accent"
                    class="mb-2 font-weight-bold secondary--text mr-0"
                    :disabled="!userpresent"
                    @click="customize(); clickedFieldGlobal('Customize Your Quote', 32, 'Renters Survey v1')"
                  >
                    Customize Your Quote
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p
                    v-if="quoteWarning"
                    class="font-weight-bold secondary--text text-center"
                  >
                    <v-icon
                      left
                      large
                      class="primary--text"
                    >
                      mdi-alert
                    </v-icon> This quote excludes wind/hail coverage. Please <router-link
                      to="/contactus"
                      target="_blank"
                    >
                      contact us
                    </router-link> for support.
                  </p>
                </v-col>
              </v-row>

              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center mt-0">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end py-0"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-cash
                    </v-icon>
                    <p class="secondary--text text-h4 font-weight-bold">
                      Deductible
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  :class="mobileDevice ? '' : 'd-flex justify-center py-0 text-center'"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      deductibleDialog = true;
                      clickedFieldGlobal(
                        'coverage deductibleDialog',
                        85,
                        'Renters Survey v1'
                      );
                    "
                  >
                    Deductible <span
                      v-if="mobileDevice"
                      class="primary--text"
                    >&#9432;</span>
                    <v-icon
                      v-if="!mobileDevice"
                      right
                      color="primary"
                    >
                      mdi-information-outline
                    </v-icon>
                  </a>
                </v-col>
                <v-col
                  v-if="!showOptions"
                  cols="8"
                  sm="4"
                  class="d-flex justify-center py-0"
                  :class="!mobileDevice ? 'py-0' : ''"
                >
                  <p class="secondary--text mb-0">
                    ${{ quoteDeductible }}
                  </p>
                </v-col>
                <v-col
                  v-if="showOptions"
                  cols="8"
                  sm="4"
                  class="d-flex justify-center"
                >
                  <v-select
                    v-model.number="deductibleSelection"
                    outlined
                    label="Deductible"
                    :items="[500, 1000, 2000]"
                    @click="
                      clickedFieldGlobal(
                        'coverage deductible',
                        86,
                        'Renters Survey v1'
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
                v-if="hurricaneDeductible"
                class="mt-0"
              >
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="pt-0"
                ></v-col>
                <v-col
                  cols="4"
                  :class="mobileDevice ? '' : 'd-flex justify-center pt-0 text-center'"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      hurricaneDeductibleDialog = true;
                      clickedFieldGlobal(
                        'coverage deductibleDialog',
                        85,
                        'Renters Survey v1'
                      );
                    "
                  >
                    Hurricane  Deductible <span
                      v-if="mobileDevice"
                      class="primary--text"
                    >&#9432;</span>
                    <v-icon
                      v-if="!mobileDevice"
                      right
                      color="primary"
                    >
                      mdi-information-outline
                    </v-icon>
                  </a>
                </v-col>
                <v-col
                  class="d-flex justify-center secondary--text"
                  :class="!mobileDevice ? 'pt-0' : ''"
                >
                  ${{ hurricaneDeductible }}
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center mt-0">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end py-0"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-home
                    </v-icon>
                    <p class="secondary--text text-h4 font-weight-bold">
                      Home
                    </p>
                  </div>
                </v-col>
                <v-col
                  sm="4"
                  class="d-flex justify-center"
                  :class="!mobileDevice ? 'py-0 text-center' : ''"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      louDialog = true;
                      clickedFieldGlobal(
                        'coverage lossOfUseDialog',
                        87,
                        'Renters Survey v1'
                      );
                    "
                  >Loss Of Use
                    <span
                      v-if="mobileDevice"
                      class="primary--text"
                    >&#9432;</span>
                    <v-icon
                      v-if="!mobileDevice"
                      right
                      color="primary"
                    >
                      mdi-information-outline
                    </v-icon></a>
                </v-col>
                <v-col
                  cols="8"
                  sm="4"
                  class="d-flex justify-center"
                  :class="!mobileDevice ? 'py-0' : ''"
                >
                  <p class="secondary--text mb-0">
                    ${{ louLimit }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center mt-0">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end py-0"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-television-classic
                    </v-icon>
                    <p class="secondary--text font-weight-bold">
                      Personal <br />
                      Property
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  class="d-flex justify-center py-0"
                  :class="!mobileDevice ? 'py-0 text-center' : ''"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      ppDialog = true;
                      clickedFieldGlobal(
                        'coverage personalPropertyDialog',
                        90,
                        'Renters Survey v1'
                      );
                    "
                  >Personal Property <span
                    v-if="mobileDevice"
                    class="primary--text"
                  >&#9432;</span><v-icon
                    v-if="!mobileDevice"
                    right
                    color="primary"
                  >
                    mdi-information-outline
                  </v-icon></a>
                </v-col>
                <v-col
                  cols="8"
                  sm="4"
                  class="text-center"
                  :class="!mobileDevice ? 'py-0' : ''"
                >
                  <p
                    v-if="!showOptions"
                    class="secondary--text mb-0 "
                  >
                    ${{ ppLimit }}
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Amount"
                    rules="required|numeric|min_value:15000|max_value:150000"
                  >
                    <v-select
                      v-if="showOptions"
                      v-model.number="ppAmount"
                      :items="personalPropertyAmounts"
                      outlined
                      label="Personal Property"
                      color="primary"
                      :error-messages="errors"
                      style="width: 100%;"
                      @click="
                        clickedFieldGlobal(
                          'coverage personalPropertyAmount',
                          91,
                          'Renters Survey v1'
                        )
                      "
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                ></v-col>
                <v-col
                  cols="5"
                  sm="4"
                  :class="mobileDevice ? '' : 'd-flex justify-center pt-0'"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      replacementDialog = true;
                      clickedFieldGlobal(
                        'coverage replacementDialog',
                        88,
                        'Renters Survey v1'
                      );
                    "
                  >Replacement <span
                    v-if="mobileDevice"
                    class="primary--text"
                  >&#9432;</span><v-icon
                    v-if="!mobileDevice"
                    right
                    color="primary"
                  >
                    mdi-information-outline
                  </v-icon></a>
                </v-col>
                <v-col
                  cols="7"
                  sm="4"
                  :class="mobileDevice ? 'd-flex justify-center' : 'd-flex justify-center pt-0'"
                >
                  <p
                    class="secondary--text mb-0 "
                  >
                    Included
                  </p>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center mt-0">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end py-0"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-shield-check
                    </v-icon>
                    <p class="secondary--text font-weight-bold">
                      Personal <br />
                      Liability
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  :class="!mobileDevice ? 'py-0 text-center' : ''"
                  class="d-flex justify-center"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      liabilityDialog = true;
                      clickedFieldGlobal(
                        'coverage liabilityDialog',
                        92,
                        'Renters Survey v1'
                      );
                    "
                  >Personal Liability <span
                    v-if="mobileDevice"
                    class="primary--text"
                  >&#9432;</span><v-icon
                    v-if="!mobileDevice"
                    right
                    color="primary"
                  >
                    mdi-information-outline
                  </v-icon></a>
                </v-col>
                <v-col
                  cols="8"
                  sm="4"
                  :class="!mobileDevice ? 'py-0' : ''"
                  class="d-flex justify-center"
                >
                  <p
                    v-if="!showOptions"
                    class="secondary--text mb-0 "
                  >
                    ${{ plLimit }}
                  </p>
                  <v-select
                    v-if="showOptions"
                    v-model="plAmount"
                    outlined
                    label="Liability Amount"
                    color="primary"
                    :items="[100000, 300000, 500000]"
                    @click="
                      clickedFieldGlobal(
                        'coverage liabilityAmount',
                        93,
                        'Renters Survey v1'
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <v-row class="d-flex align-center mt-0">
                <v-col
                  v-if="!mobileDevice"
                  cols="4"
                  class="d-flex flex-column justify-end py-0"
                >
                  <div class="text-center">
                    <v-icon
                      x-large
                      color="secondary"
                    >
                      mdi-medical-bag
                    </v-icon>
                    <p class="secondary--text font-weight-bold">
                      Medical <br />
                      Payments
                    </p>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  :class="mobileDevice ? '' : 'd-flex justify-center py-0'"
                >
                  <a
                    class="secondary--text text-decoration-underline"
                    @click="
                      mpDialog = true;
                      clickedFieldGlobal(
                        'coverage medicalPaymentDialog',
                        94,
                        'Renters Survey v1'
                      );
                    "
                  >Medical Payments <span
                    v-if="mobileDevice"
                    class="primary--text"
                  >&#9432;</span><v-icon
                    v-if="!mobileDevice"
                    right
                    color="primary"
                  >
                    mdi-information-outline
                  </v-icon></a>
                </v-col>
                <v-col
                  cols="8"
                  sm="4"
                  :class="mobileDevice ? 'text-center' : 'd-flex justify-center py-0'"
                >
                  <p
                    v-if="!showOptions"
                    class="secondary--text mb-0 "
                  >
                    ${{ medpmLimit }}
                  </p>
                  <v-select
                    v-if="showOptions"
                    v-model="medpmAmount"
                    outlined
                    label="Medical Payments Amount"
                    color="primary"
                    :items="[1000, 2000, 5000]"
                    @click="
                      clickedFieldGlobal(
                        'coverage medicalPaymentAmount',
                        95,
                        'Renters Survey v1'
                      )
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-divider class="primary" />
                </v-col>
              </v-row>
              <!-- <div v-if="discounts.length > 0 || maxDiscount">
                <v-row class="mt-0">
                  <v-col>
                    <v-divider class="primary" />
                  </v-col>
                </v-row>
                <v-row
                  class="d-flex align-center"
                  :class="mobileDevice ? 'mt-4' : 'mt-6'"
                >
                  <v-col
                    cols="4"
                    class="d-flex flex-column justify-end"
                  >
                    <div class="text-center">
                      <v-icon
                        x-large
                        color="orange"
                      >
                        mdi-piggy-bank-outline
                      </v-icon>
                      <p class="orange--text text-h4 font-weight-bold">
                        Discounts
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="4"
                    md="4"
                    lg="4"
                    class="d-flex flex-column justify-start"
                  >
                    <div
                      v-if="maxDiscount"
                      class="d-inline-flex pb-2 orange--text"
                    >
                      Max Discount Applied
                    </div>
                    <div v-else>
                      <div
                        v-for="(discount, index) in discounts"
                        :key="index"
                        class="pb-2 orange--text"
                      >
                        <span class="orange--text">{{
                          discount.CoverageCd[0]
                        }}</span>
                        <br />
                        <span class="orange--text">{{
                          formatDiscount(discount.CurrentTermAmt[0].Amt[0])
                        }}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    v-if="!mobileDevice"
                    cols="6"
                    sm="3"
                    md="3"
                    lg="3"
                  >
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col>
                    <v-divider class="primary" />
                  </v-col>
                </v-row>
              </div> -->
              <v-row v-if="!showOptions">
                <v-col class="d-flex justify-center align-center">
                  <v-btn
                    x-large
                    color="primary"
                    class="mb-2 font-weight-bold mr-0"
                    :disabled="!userpresent"
                    @click="
                      next();"
                  >
                    Continue To Purchase
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showOptions">
                <v-col

                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-btn

                    :disabled="invalid"
                    x-large
                    color="accent"
                    class="mb-2 font-weight-bold secondary--text mr-0"
                    @click="
                      recalculate();
                      clickedFieldGlobal(
                        'coverage Recalculate Quote',
                        99,
                        'Renters Survey v1'
                      );
                    "
                  >
                    Recalculate Quote
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                >
                  <v-btn

                    x-large
                    color="secondary"
                    class="mb-2 font-weight-bold white--text mr-0"
                    @click="
                      showOptions = false;
                      clickedFieldGlobal(
                        'coverage viewQuote',
                        98,
                        'Renters Survey v1'
                      );
                    "
                  >
                    View Quote
                  </v-btn>
                </v-col>
              </v-row>
              <!----------------------DIALOGS--------------------->
              <v-dialog
                v-model="deductibleDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      This is the amount you've agreed to pay out of your pocket before the insurance company pays for any covered losses. A higher deductible usually means a lower insurance premium. This deductible will apply to your personal property coverage.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        deductibleDialog = false;
                        clickedFieldGlobal(
                          'coverage deductibleDialogCloseBtn',
                          101,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="hurricaneDeductibleDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      In certain locations, you are required to have a separate hurricane deductible.
                      This deductible is applied separately from the standard deductible and will apply to any direct or indirect losses from damages caused by a hurricane.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        hurricaneDeductibleDialog = false;
                        clickedFieldGlobal(
                          'coverage deductibleDialogCloseBtn',
                          101,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="louDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Covers additional living expenses, meaning any necessary expense that exceeds what you normally spend prior to the covered peril.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        louDialog = false;
                        clickedFieldGlobal(
                          'coverage lossOfUseDialogCloseBtn',
                          102,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="replacementDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      The insurance company will pay what it costs to repair or replace your damaged possessions at today's prices without deducting for depreciation.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        replacementDialog = false;
                        clickedFieldGlobal(
                          'coverage replacementDialogCloseBtn',
                          103,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="ppDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Helps replace your belongings such as furniture, clothing and electronics after loss or damage from fire, water and more.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        ppDialog = false;
                        clickedFieldGlobal(
                          'coverage ppDialogCloseBtn',
                          104,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="liabilityDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Helps pay for covered damages that you're legally obligated to pay, such as bodily injury and property damage lawsuits.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        liabilityDialog = false;
                        clickedFieldGlobal(
                          'coverage liabilityDialogCloseBtn',
                          105,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="mpDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-card-text>
                    <div class="text-h4 pa-4">
                      Helps pay for medical expenses on covered losses for visitors who are injured on your property.
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end">
                    <v-btn
                      class="font-weight-bold"
                      color="primary"
                      x-large
                      @click="
                        mpDialog = false;
                        clickedFieldGlobal(
                          'coverage mpDialogCloseBtn',
                          106,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!----------------------------------------MOBILE--------------------------------------->
              <!-- <div
                v-if="mobileDevice"
                id="mobileButtons"
              >
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      v-if="!showOptions"
                      x-large
                      color="accent"
                      class="mb-2 font-weight-bold secondary--text"
                      @click="customize(); clickedFieldGlobal('Customize Your Quote', 32, 'Renters Survey v1')"
                    >
                      Customize Your Quote
                    </v-btn>
                    <v-btn
                      v-if="showOptions"
                      x-large
                      color="secondary"
                      class="mb-2 font-weight-bold white--text"
                      @click="
                        showOptions = false;
                        clickedFieldGlobal(
                          'coverage cancelMobileBtn',
                          109,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      v-if="showOptions"
                      x-large
                      color="accent"
                      class="mb-2 font-weight-bold secondary--text"
                      @click="
                        recalculate();
                        clickedFieldGlobal(
                          'coverage Recalculate Quote Mobile',
                          110,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Recalculate Quote
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      x-large
                      color="primary"
                      class="mb-2 font-weight-bold"
                      @click="
                        next();
                        clickedFieldGlobal(
                          'Continue To Purchase Mobile',
                          111,
                          'Renters Survey v1'
                        );
                      "
                    >
                      Continue To Purchase
                    </v-btn>
                  </v-col>
                </v-row>
              </div> -->
              <v-overlay v-if="loading">
                <v-row
                  v-if="loading"
                  class="mt-12"
                >
                  <v-col class="d-flex justify-center">
                    <ellipse-progress
                      :loading="loading"
                      :size="180"
                      img-src="src/assets/images/renters-icon.png"
                      thickness="5"
                      empty-thickness="3"
                      line-mode="out 5"
                      legend="false"
                      animation="rs 700 1000"
                      font-size="1.5rem"
                      color="#00A1B7"
                      empty-color-fill="transparent"
                      filename="renters-icon.png"
                    >
                    </ellipse-progress>
                  </v-col>
                </v-row>
              </v-overlay>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="errorDialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-2">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>
              We’re sorry that we are unable to process your application at this time due to the following error:
            </v-col>
          </v-row>
          <v-row>
            <v-col class="font-italic">
              {{ error }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="d-flex justify-center"
            no-gutters
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/renters/info"
                @click="clearError"
              >
                Restart Application
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                to="/"
                @click="clearError"
              >
                Return to Home
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-row
          no-gutters
          class="d-flex justify-center font-weight-bold text-h5"
        >
          <v-col class="d-flex justify-center font-weight-bold text-center">
            Please contact us at 1-(888)-333-3145 for support with your application.
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!--------------LOGIN/CREATE ACCOUNT ERROR DIALOG----------->
    <v-dialog
      v-model="loginErrorDialog"
      max-width="700"
      persistent
    >
      <v-card class="pa-2">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col class="font-italic">
              {{ loginError }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row
            class="d-flex justify-center"
            no-gutters
          >
            <v-col class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 mr-0 font-weight-bold"
                @click="clearLoginError"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <otp-verification
      path="src/assets/images/renters-icon.png"
      filename="renters-icon.png"
    />
  </v-container>
</template>

<script>
  import numeral from 'numeral'
  import moment from 'moment'
  import CreateAccountButton from '../../../components/base/CreateAccountBtn'
  export default {
    components: {
      CreateAccountButton
    },
    data () {
      return {
        loginErrorDialog: false,
        show: null,
        user: {
          email: null,
          password: null
        },
        hurricaneDeductibleDialog: false,
        isFlashing: false,
        moldDialog: false,
        errorDialog: false,
        rateChangeDialog: false,
        deductibleDialog: false,
        louDialog: false,
        replacementDialog: false,
        ppDialog: false,
        liabilityDialog: false,
        mpDialog: false,
        alDialog: false,
        showOptions: false,
        deductibleSelection: '',
        ppAmount: '',
        plAmount: '',
        medpmAmount: '',
        alCheckbox: true
      }
    },
    computed: {
      renter () {
        return this.$store.getters.getRenter
      },
      userpresent () {
        return this.$store.getters.getuser !== null
      },
      hurricaneDeductible () {
        return this.getHurricaneDeductible(this.quote)
      },
      quoteWarning () {
        return this.$store.getters.getRentersQuoteWarning
      },
      policyDates () {
        return { effectiveDate: this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].ContractTerm[0].EffectiveDt[0], expirationDate: moment(this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0].PersPolicy[0].ContractTerm[0].EffectiveDt[0]).add(1, 'years').format('YYYY-MM-DD') }
      },
      personalPropertyAmounts () {
        let amt = []
        for (let i = 15000; i <= 75000; i += 5000) {
          amt.push(i)
        } return amt
      },
      error () {
        return this.$store.getters.error
      },
      loginError () {
        return this.$store.getters.getloginerror
      },
      coverageList () {
        return [
          {
            coverageCd: 'PP',
            limits: [
              {
                formatCurrencyAmt: {
                  amt: this.ppAmount
                }
              }
            ],
            deductible: {
              formatInteger: this.deductibleSelection,
              deductibleAppliesToCd: 'AllPeril'
            }
          },
          {
            coverageCd: 'RCC'
          },
          {
            coverageCd: 'PL',
            limits: [
              {
                formatCurrencyAmt: {
                  amt: this.plAmount
                }
              }
            ]
          },
          {
            coverageCd: 'MEDPM',
            limits: [
              {
                formatCurrencyAmt: {
                  amt: this.medpmAmount
                }
              }
            ]
          }
        ]
      },
      loading () {
        return this.$store.getters.getloading
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      maxDiscount () {
        let maxDiscount = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MaxDiscountAdjustmnt'
        )
        if (maxDiscount) {
          return true
        }
        return false
      },
      quote () {
        return this.$store.getters.getRentalQuote
      },
      quotePremium () {
        return numeral(
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .PolicySummaryInfo[0].FullTermAmt[0].Amt[0]
        ).format('0,0.00')
      },
      quoteDeductible () {
        return numeral(
          this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
            .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0]
            .FormatInteger[0]
        ).format('0,0.00')
      },
      deductibleFormat () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].Coverage[0].Deductible[0]
          .FormatInteger[0]
      },
      louLimit () {
        let lou = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'LOU'
        )
        return numeral(lou.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      coverages () {
        return this.quote.ACORD.InsuranceSvcRs[0].HomePolicyQuoteInqRs[0]
          .HomeLineBusiness[0].Dwell[0].Coverage
      },
      discounts () {
        return this.$store.getters.getRentalDiscounts
      },
      ppCost () {
        let pp = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PP')
        return numeral(pp.CurrentTermAmt[0].Amt[0]).format('0,0.00')
      },
      ppLimit () {
        let pp = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PP')
        return numeral(pp.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      ppFormat () {
        let pp = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PP')
        return parseInt(pp.Limit[0].FormatInteger[0])
      },
      plCost () {
        let pl = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PL')
        if (pl.CurrentTermAmt !== undefined) {
          return numeral(pl.CurrentTermAmt[0].Amt[0]).format('0,0.00')
        } else {
          return 'Included'
        }
      },
      plLimit () {
        let pl = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PL')
        return numeral(pl.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      plFormat () {
        let pl = this.coverages.find(coverage => coverage.CoverageCd[0] === 'PL')
        return parseInt(pl.Limit[0].FormatInteger[0])
      },
      medpmCost () {
        let medpm = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MEDPM'
        )
        if (medpm.CurrentTermAmt !== undefined) {
          return numeral(medpm.CurrentTermAmt[0].Amt[0]).format('0,0.00')
        } else {
          return 'Included'
        }
      },
      medpmLimit () {
        let medpm = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MEDPM'
        )
        return numeral(medpm.Limit[0].FormatInteger[0]).format('0,0.00')
      },
      medpmFormat () {
        let medpm = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MEDPM'
        )
        return parseInt(medpm.Limit[0].FormatInteger[0])
      },
      alCost () {
        let animalLiability = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'AnimalLiability'
        )
        if (animalLiability) {
          return numeral(animalLiability.CurrentTermAmt[0].Amt[0]).format(
            '0,0.00'
          )
        }
        return null
      },
      // hurCost () {
      //   let hurricane = this.coverages.find(coverage => coverage.CoverageCd[0] === 'HURR')
      //   if (hurricane) {
      //     return numeral(hurricane.CurrentTermAmt[0].Amt[0]).format('0,0.00')
      //   }
      //   return null
      // },
      moldCost () {
        let mold = this.coverages.find(
          coverage => coverage.CoverageCd[0] === 'MOLD'
        )
        if (mold) {
          if (mold.CurrentTermAmt !== undefined) {
            return numeral(mold.CurrentTermAmt[0].Amt[0]).format('0,0.00')
          }
          return 'Included'
        }
        return null
      }
    },
    watch: {
      error (newValue) {
        console.log('error changed:', newValue)
        this.errorDialog = !!newValue
      },
      loginError (newValue) {
        console.log('loginError changed:', newValue)
        this.loginErrorDialog = !!newValue
      },
    },
    mounted () {
      this.user.email = this.renter.email
    },
    created () {
      console.log('Quote', this.$store.getters.getRentalQuote)
    },
    methods: {
      clearLoginError () {
        this.$store.dispatch('clearLoginError')
        this.loginErrorDialog = false
      },
      createApplicantAccount () {
        this.$store.dispatch('createApplicantAccount', {
          email: this.user.email,
          password: this.user.password
        })
      },
      loginSubmit () {
        this.$store.dispatch('signApplicantIn', {
          email: this.user.email,
          password: this.user.password
        })
      },
      createAccount () {
        this.$store.dispatch('createUserAndProfileForUser', {
          email: this.user.email
        })
      },
      getHurricaneDeductible (quote) {
        const defaultDeductible = ''

        const dwell =
        quote?.ACORD?.InsuranceSvcRs[0]?.HomePolicyQuoteInqRs[0]
          ?.HomeLineBusiness[0]?.Dwell[0]

        if (!dwell?.Coverage) {
          return defaultDeductible
        }

        const hurrCoverage = dwell.Coverage.find(
          (coverage) => coverage.CoverageCd[0] === 'HURR'
        )

        if (!hurrCoverage || !hurrCoverage.Deductible) {
          return defaultDeductible
        }

        const hurrDeductible = hurrCoverage.Deductible.find(
          (deductible) => deductible.DeductibleAppliesToCd[0] === 'HURR'
        )

        if (!hurrDeductible) {
          return defaultDeductible
        }

        return numeral(hurrDeductible.FormatInteger[0]).format('0,0.00')
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
      },
      toPay () {
        this.$store.dispatch('borderfreeCreateOrder').then(() => {
          this.$router.push({ path: '/renters/pay' })
        })
      },
      async next () {
        try {
          await this.$store.dispatch('rentersPreBind')
          if (!this.error) {
            this.$router.push({ path: '/renters/pay' })
          }
        } catch (error) {
          console.error('Error in next method:', error)
        }
      },
      customize () {
        this.showOptions = true
        this.deductibleSelection = parseInt(this.quoteDeductible.replace(',', ''))
        this.ppAmount = this.ppFormat
        this.plAmount = this.plFormat
        this.medpmAmount = this.medpmFormat
        console.log(this.deductibleSelection)
      },
      async recalculate () {
        console.log('Recalculating quote')
        await this.$store.dispatch('getRevisedRentersQuote', this.coverageList)

        // Scroll to the top and set isFlashing to true
        this.showOptions = false
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.isFlashing = true
        for (let i = 0; i < 4; i++) {
          this.isFlashing = true
          await new Promise(resolve => setTimeout(resolve, 750))
          this.isFlashing = false
          await new Promise(resolve => setTimeout(resolve, 500))
        }

        // Set isFlashing back to false to remove the flash class
        this.isFlashing = false
      }
    }
  }
</script>

<style lang="scss" scoped>

.message-text {
  color: #757575;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00a1b7;
}
.sub-title {
  font-size: 20px;
  font-weight: 700;
  color: #00a1b7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005f6c;
  color: white;
}

.top-divider {
  height: 4px;
}

.v-input__control {
  height: 50px !important;
}

@keyframes flashAnimation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.flash {
  animation: flashAnimation 1s;
}
</style>
